// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-api-js": () => import("./../../../src/pages/api.js" /* webpackChunkName: "component---src-pages-api-js" */),
  "component---src-pages-apiextensions-js": () => import("./../../../src/pages/apiextensions.js" /* webpackChunkName: "component---src-pages-apiextensions-js" */),
  "component---src-pages-guest-operator-js": () => import("./../../../src/pages/guest-operator.js" /* webpackChunkName: "component---src-pages-guest-operator-js" */),
  "component---src-pages-host-operator-js": () => import("./../../../src/pages/host-operator.js" /* webpackChunkName: "component---src-pages-host-operator-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

